@import "../../style/variables.scss";
@import "../../style/mixin.scss";

.customer-row {
    display: grid;
    grid-template-columns: 320px minmax(0, calc(100% - 320px ));
    @media screen and (max-width: 900px) {
        grid-template-columns: 1fr;
        width: calc(100vw - 80px);
    }
}

.customer-left {
    color: $color_grey;
    // width: 320px;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 900px) {
        width: calc(100vw - 80px);
    }
   
    &-bread {
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0%;
        display: flex;
        gap: 14px;
        color: $color_grey8;
        align-items: center;
        padding: 32px 0 0 40px;
    }
    &-body {
        padding: 37px 32px 36px 36px;
    }
    &-docs {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $color_white1;
        font-size: 14px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0px;
        text-align: center;
        gap: 10px;
        margin-top: 20px;
        &-item {
            height: 60px;
            &-img {
                height: 40px;
                width: auto;
                @include flex-all-center;
                img {
                    width: auto;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
    }
    &-info {
        margin-top: 52px;
        &-title {
            font-size: 20px;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: 0px;
            display: flex;
            color: $color_grey5;
            gap: 5px;
        }
        &-text {
            margin-top: 12px;
            border: 1px solid $color_grey5;
            border-radius: 5px;

            padding: 20px;
            position: relative;
            &-pen {
                position: absolute;
                top: 13px;
                right: 13px;
            }

            color: $color_white1;
            display: flex;
            flex-direction: column;
            gap: 5px;
            font-size: 20px;
            font-weight: 400;
            line-height: 30px;
            letter-spacing: 0px;
            input {
                color: $color_white1;

                &::placeholder {
                    color: $color_white1;
                }
            }
        }
    }
    &-company {
        margin-top: 31px;
        &-title {
            display: flex;
            justify-content: space-between;
            font-size: 20px;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: 0px;

            color: $color_grey5;
            &-item {
                display: flex;
                align-items: center;

                gap: 5px;
                span {
                    font-size: 36px;
                }
            }
        }
        &-select {
            margin-top: 10px;
        }
    }
    &-prof {
        margin-top: 27px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        color: $color_white1;
        &-item {
            font-size: 20px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0px;
            padding: 12px 10px;
            display: flex;
            gap: 10px;
            align-items: center;
            border: 1px solid $color_grey5;
            border-radius: 5px;
        }
    }
    &-meneger {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        min-height: 250px;
        &-text {
            text-align: end;
            font-size: 24px;
            font-weight: 400;
            line-height: 30px;
            letter-spacing: 0px;
            color: $color_white1;
            p {
                color: $color_grey5;
                font-size: 20px;
            }
        }
        &-messenger {
            margin-top: 48px;
            display: flex;
            justify-content: space-between;
            padding-left: 10px;
            img {
                width: 40px;
                height: 40px;
            }
        }
    }
}

.customer-right {
    margin-top: 2px;
    margin-bottom: 2px;
    background: $color_grey9;
    flex-grow: 1;
    @media screen and (max-width: 900px) {
        width: calc(100vw - 80px);
    }
    &-hello {
        padding: 96px 0 0 36px;
        @media screen and (max-width: 900px) {
            padding: 47px 18px 0 12px;
        }
        &-img {
            width: 216px;
            height: auto;
            img {
                width: 100%;
                height: 100%;
            }
        }

        &-text {
            margin-top: 5px;
            display: grid;
            gap: 90px;
            grid-template-columns: repeat(2, minmax(0, 530px));
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0px;
            color: $color_black3;
           
            p {
                padding-left: 30px;
            }
            ul {
                list-style-type: disc;
                padding-left: 20px;
            }
            @media screen and (max-width: 1400px) {
                gap: 40px;
            }
            @media screen and (max-width: 1000px) {
                grid-template-columns: 1fr;
                p{
                    padding: 20px 0px 0 0;
                }
                ul {
                
                    padding: 0;
                }
            }
        }
    }
    &-order {
        margin: 25px 0 0 36px;
        h6 {
            display: flex;
            gap: 5px;
            font-size: 24px;
            font-weight: 600;
            line-height: 53px;
            letter-spacing: 0px;
            align-items: center;
        }
        @media screen and (max-width: 900px) {
            margin: 11px 0 0 18px;
        }
        &-table{
            overflow: hidden ;
            @media screen and (max-width: 1300px) {
                overflow-x: scroll;
                padding-right: 20px;
            }
            @media screen and (max-width: 900px) {
                display: none;
            }
        }
        table {
            width: calc(100% - 36px);
            border-collapse: collapse;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            letter-spacing: 0%;
            @media screen and (max-width: 1400px) {
                width: 750px;
            }
        }

        td {
            border: 1px solid rgba(29, 29, 29, 0.1);
            padding: 16px 8px;
            text-align: center;
            color: $color_grey1;
            font-weight: 600;
            box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.1);
            background: rgb(255, 255, 255);
        }

        th {
            color: $color_grey2;

            padding: 8px 8px 16px 8px;
            text-align: center;
            border: none;
        }
        &-watch {
            color: $color_grey2 !important;
            display: flex;
            justify-content: center;
            gap: 16px;
            align-items: center;
            cursor: pointer;
        }
    }
    &-desire{
       
        margin-top: 132px;
        @media screen and (max-width: 900px) {
            margin-top: 25px;
        }
        &-title{
            padding-left: 36px;
            display: flex;
            align-items: center;
            gap: 20px;
            font-size: 24px;
            letter-spacing: 0px;
           
            &-like{
                font-weight: 600;
                line-height: 53px;
                display: flex;
                align-items: center;
                gap: 7px;
                color: $color_black3;
            }
            span{

                line-height: 53px;
                font-weight: 400;
                color: $color_grey5;
            }
            @media screen and (max-width: 900px) {
                display: none;
            }
        }
        &-list{
            cursor: grab !important;
            position: relative;
            .catalog-filter-list-item{
                cursor: grab !important;
            }
            .catalog-filter-list-item-foot{
                @media screen and (max-width: 900px) {
                    max-width: calc(100vh - 80px - 22px);
                }
            }
            &-filt{
                display: none;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                justify-content: space-between;
                padding: 11px;
                color: $color_black;
                font-size: 16px;
                font-weight: 500;
                line-height: 19px;
                letter-spacing: 0%;
                align-items: center;
                svg{
                    width: 22px;
                    height: 22px;
                }
                button{
                    display: flex;
                    align-items: center;
                    gap: 9px;
                }
                @media screen and (max-width: 900px) {
                    display: flex;
                }
            }
        
        }
    }
    &-reload{
        display: none;
        @media screen and (max-width: 900px) {
            display: flex;
            padding: 40px 20px;
            align-items: center;
            gap: 10px;
            font-size: 24px;
            color: $color_black;
            font-weight: 400;
        }
    }
 
   
}

.customer-footer{
    background-color: $color_black;
    padding: 80px 180px 67px 180px ;
    font-size: 20px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0%;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    color: $color_white1;
    &-list{
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    h6{
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 10%;
        text-align: left;
        text-transform: uppercase;
        color: $color_grey5;
    }
    ul{
        list-style-type: disc;
        padding-left: 40px;
        li{
            text-decoration-line: underline;
        }
    }
    &-work{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100% - 25px);
    }
    h5{
        color: $color_grey5;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
    }
    @media screen and (max-width:1400px) {
        padding: 80px 90px 67px 90px ;
     } 
     @media screen and (max-width:1100px) {
        padding: 80px 40px 67px 40px ;
     }
    @media screen and (max-width: 900px) {
       display: none;
    }

}

.customer-right-swiper {
    cursor: grab;
    margin: 64px 0 0 36px;
    display: flex;
    width: calc(100% - 36px);
    @media screen and (max-width: 900px) {
        margin: 11px 0 0 7px;
        height: 600px; 
        overflow: hidden; 
        width: calc(100% - 14px);
    }
    &-item {
        width: 402px;
        height: 253px;
        padding: 30px;

        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        justify-content: space-between;
        align-content: space-between;
        gap: 20px;
        height: 100%;
        color: $color_grey;
        letter-spacing: 0%;
      
        background: $color_white;
        @media screen and (max-width: 900px) {
            width: calc(100vw - 80px );
            padding: 10px;
            gap: 10px;
        }
        &-1 {
            font-size: 32px;
            font-weight: 500;
            display: flex;
      
            gap: 8px;
        }
        &-2 {
            font-size: 19.38px;
            font-weight: 500;
            line-height: 23px;
         
        }

        &-3 {
            text-align: end;
            font-size: 16px;
            font-weight: 600;
            line-height: 19px;
            padding-right: 10px;
        }
        &-4 {
            font-size: 20px;
            font-weight: 400;
            line-height: 23px;
  
        }
        &-5 {
            font-size: 24px;
            font-weight: 500;
            line-height: 28px;
   
        }
        &-6 {
            text-align: end;
            font-size: 20px;
            font-weight: 500;
            line-height: 23px;
            padding-right: 10px;

        }
        &-see{
            display: none;

            align-items: center;
            justify-content: flex-end;
            gap: 8px;
            padding-right: 18px;
            background-color: rgba(34, 34, 34, 0.1);
            height: 56px;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            letter-spacing: 0%;
            color: $color_black3;
            width: calc(100vw - 80px );
            button{
                margin-right: 18px;
            }
            @media screen and (max-width:900px) {
               display: flex;
           
            }
        }
    }
    &-black{
        .customer-right-swiper-item{
            color: $color_white;

          
            background:$color_black3;
            &-2{
                color: $color_grey2;
            }
            &-6{
                color: $color_grey2;
            }
        }
    }
}

.customer--select {
    width: 100%;
    border: 1px solid $color_grey5;
    border-radius: 5px;
    position: relative;
    height: 48px;
    padding: 12px 14px;
    color: $color_white1;

    font-size: 20px;
    font-weight: 400;

    letter-spacing: 0px;
    &-chevron {
        position: absolute;
        top: 12px;
        right: 19px;

        svg {
            height: 12px;
            width: 6px;
        }
        transition: transform 0.2s ease-in-out;
        &-active {
            transform: rotate(90deg);
        }
    }
    &-list {
        padding: 17px 0px;
        text-align: start;
        position: absolute;
        max-height: 250px;
        top: 50px;
        left: 0;
        height: fit-content;
        width: 100%;
        background-color: $color_black3;
        &-item {
            padding: 5px 14px;
            width: 100%;
            text-align: start;
            &:hover {
                background-color: $color_grey;
            }
        }
        display: none;
        &-active {
            display: flex;
        }

        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
        z-index: 10;
    }
}
