@import "../../style/variables.scss";
@import "../../style/mixin.scss";
@import "../../style/default-style.scss";
.footer {
    display: grid;
    grid-template-columns: 80px 1fr 80px;
    background-color: $color_grey;
    border: 1px solid $color_border;
    height: 80px;
    position: relative;
    .basket {
        display: none;
    }
    &-item {
        width: 79px;
        @include flex-all-center;
        &-l {
            border-left: 1px solid $color_border;
        }
        &-r {
            border-right: 1px solid $color_border;
        }
    }
    &-info {
        padding: 15px;
        display: flex;
        align-items: center;
    }
    &-text {
        flex-grow: 1;
        display: flex;
        gap: 5px;
        color: $color_white;
        opacity: 0.6;
        font-weight: 300;
        font-size: 18px;
        line-height: 111%;
        text-transform: uppercase;
        &-gr {
            color: $color_white;
            opacity: 0.2;
        }
    }
    &-inv {
        font-weight: 400;
        font-size: 18px;
        color: $color_white;
        gap: 5px;
        display: flex;
    }
    &-mob {
        display: none;
    }
}

@media screen and (max-width: 700px) {
    .footer {
        width: 100%;
        grid-template-columns: 1fr;
        z-index: 103;
        .basket {
            top: 79px;
            display: flex;
            position: fixed;
            height: calc(100% - 80px) !important;
            width: calc(100vw - 80px);
        
        }
    }
    .footer-item {
        display: none;
    }
    .footer-info {
        display: none;
    }
    .footer-mob {
        display: grid;
        height: 81px;

        grid-template-columns: repeat(5, 1fr);
        width: 100%;
        &-item {
            border-top: 1px solid $color_border;
        }
    }
}
