@import "../../style/variables.scss";
@import "../../style/mixin.scss";
@import "../../style/default-style.scss";
@import "../catalog//catalog.scss";
.navbar {
    background-color: $color_grey;
    position: relative;
    z-index: 100;
    border-left: 1px solid $color_border;
    border-right: 1px solid $color_border;
    &-item {
        position: relative;
        background-color: $color_grey;
        z-index: 9999;
        width: 78px;
        height: 80px;
        border-bottom: 1px solid $color_border;
        
        @include flex-all-center;
        p{
            display: none;
        }
    }
    &:hover {
        .navbar-titles {
            transform: translateX(80px);
        }
        .navbar_products{
            transition-delay: 0.5s;
            opacity: 1;
            width: calc(100vw - 300px);
            
        }
    }
}

.right-navbar {
    min-height: 100%;
    width: 81px;
    background-color: $color_grey;
    border-left: 1px solid $color_border;
    border-right: 1px solid $color_border;
}

.navbar_products::-webkit-scrollbar {
    width: 0px; 
}

.navbar_products {
    scrollbar-width: none; 
}
.navbar-item-ico{
    transition: stroke 0.3 ease;
    &:hover{
        path{
            stroke: #e040fb;
            transition: stroke 0.3 ease;
        }
    }
}
.navbar_products{
    transition: opacity 0.3s ease-in;
    transition-delay: 0.3s;
    opacity: 0;
    width: 0;
    max-height: 800px;
    overflow-y: scroll;
    max-width: 1220px;
}

.navbar-titles {
    z-index: 9998;
    position: absolute;
    top: 0px;
    left: -2px;
    width: 160px;
    height: 800px;
    background-color: $color_grey;
    transform: translateX(-158px);
    transition: transform 0.5s;
    display: grid;
    grid-template-columns: 159px 1fr;
    
    &-item {
        padding: 10px;
        width: 160px;
        height: 80px;
        border: 1px solid $color_border;
        font-weight: 500;
        font-size: clamp(0.75rem, 1.25rem, 1.25rem);
        text-transform: uppercase;
        color: #fff;
        opacity: 0.6;
        display: flex;
        align-items: center;
        transition: all 0.5s;
        &:hover {
            background: $color_grey1;
            opacity: 1;
           
        }
    }
}




@media screen and (max-width: 700px) {
  .right-navbar{
    display: none;
  }
 .navbar-titles{
    display: none;
 }
  .navbar-item{
    display: flex;
    flex-direction: column;
    gap: 8px;
    svg{
        width: 32px;
    }
    p{
        color: $color_white;
        display: block;
        opacity: 0.6;
        font-size: 12px;
    }
  }
}
