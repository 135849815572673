
@import "variables.scss";

@mixin flex-all-center {
    display: flex;
    justify-content: center;
    align-items: center;
    display: -webkit-flex;
    -webkit-justify-content: center;
    -webkit-align-items: center;
}

@mixin three-dots($mw:200px) {
    max-width: $mw;
    white-space: nowrap;    
    text-overflow: ellipsis;
    overflow: hidden; 
}


@mixin gradient-text($bgr: $backgroundgradient2) {
    text-align: center;
    background: $bgr;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


 