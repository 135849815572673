@import "../../style/variables.scss";
@import "../../style/mixin.scss";
@import "../../style/default-style.scss";
.header {
    height: 80px;
    display: grid;
    grid-template-columns: 79px 140px 1fr 320px;
    background-color: $color_grey;
    position: relative;
    @media screen and (max-width: 700px) {
        .basket{
            display: none;
        }

    }
    &-logo{
        width: 80px;
        border: 1px solid $color_border;
        
        @include flex-all-center;
        &-video{
            width: 100%;
            height: 100%;
        }
    }
    &-navbar{
        display: none;
    }
    &-links {
        &-mob-flag {
            display: none;
        }
        display: flex;
        padding: 10px;
        text-wrap: nowrap;
        align-items: center;
        border: 1px solid $color_border;
        border-left: 0;
        border-right: 0;
        &-name {
            width: 160px;
            display: flex;
            font-size: 20px;
            gap: 5px;
            color: $color_white;
            @include flex-all-center;
            h5 {
                font-weight: 700;
                display: inline;
            }
            p {
                display: inline;
                font-weight: 400;
            }
            border: 1px solid $color_border;
            border-right: 0;
            border-left: 0;
        }
        &-catalog {
            width: 120px;
        }
        &-nav {
            max-width: 620px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            gap: 10px;
            &-item {
                font-weight: 400;
                font-size: clamp(0.75rem, 1.25rem, 1.25rem);
                color: $color_white;
                opacity: 0.6;
            }
            &-active {
                font-weight: 500;
                text-transform: uppercase;
                color: $color_white;
                opacity: 1;
            }
        }
    }
    &-item {
        background-color: $color_grey;
        width: 79px;
        border-right: 1px solid $color_border;
        transition: all 0.3 ease;
        &:hover{
            svg:first-child{
                path{
                    fill: #4aff0b;
                    transition: fill 0.3s ease; 
                }
            }
        }
        @include flex-all-center;
        &-search {
            position: relative;
            &-active {
                background: $backgroundgradient1;
                border: 0;
            }
        }
    }
    &-buttons {
        display: flex;
        border: 1px solid $color_border;
        justify-content: flex-end;
    }
}

.header-search {
    position: absolute;
    width: 0;
    top: 0px;
    right: 79px;
    background-color: $color_white;
    height: 78px;
    transition: all 0.5s;
    display: flex;
    align-items: center;
    &-icon {
        width: 100%;
        height: 100%;
        z-index: 2;
        @include flex-all-center;
    }
    &-active {
        width: calc(100vw - 50px - 120px - 180px - 320px);
    }
    &-line {
        margin: 0 10px 0 20px;
        width: 1px;
        height: 39px;
        background: $backgroundgradient1;
    }
    &-input {
        font-weight: 400;
        font-size: 24px;
        color: $color_grey1;
        width: 100%;
        &::placeholder {
            font-weight: 400;
            font-size: 24px;
            color: $color_grey1;
            opacity: 0.8;
        }
    }
    &-mic {
        padding-right: 30px;
    }
}

@media screen and (max-width: 1200px) {
    .header {
        grid-template-columns: 80px 1fr;
       
        width: 100%;
        z-index: 102;
    }
    .header-links {
        display: none;
    }
    .header-buttons {
        display: none;
    }
    .header-links-name {
        padding: 0px 20px 0 10px;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    .header-links-mob-flag {
        display: flex;
        gap: 50px;
        font-size: 10px;
        font-weight: 400;
        color: $color_white;
        align-items: center;
        button {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;
           
        }
        &-svg{
            height: 32px;
            width: 42px;
            @include flex-all-center;
        }
    }
    .header-navbar{
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 80px;
        left: 100%;
        width: calc(100% - 80px);
        transition: all 0.5s;
        background-color: $color_grey1;
        color: $color_white;
        z-index: 2;
        padding: 25px 0 25px 75px;
        &-active{
            transform: translateX(-100%);
        }
        &-item{
            opacity: 0.6;
            color: $color_white;
            font-size: 24px;
            font-weight: 400;
            line-height: -1px;
            letter-spacing: 0%;
            padding-bottom: 23px;
            &-title{
                padding-top: 25px;
                padding-bottom: 13px;
                opacity: 1;
                font-size: 32px;
                font-weight: 400;
                line-height: -1px;
            }
        }
        &-inv{
            display: flex;
            align-items: center;
            gap: 7px;
            margin-bottom: 35px;
        }
        &-paymant{
            margin-bottom: 75px;
            img{
                
                height: 40px;
            }
        }
        &-text{
            position: absolute;
            bottom: 31px;
            left: 16px;
            @include three-dots(300px);
            font-size: 12px;
            opacity: 0.6;
            color: $color_white;
            b{
                opacity: 1;
                font-weight: 700;
            }
        }
   
    }
}
