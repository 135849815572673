@import "../../style/variables.scss";
@import "../../style/mixin.scss";

.auth {
    max-height: inherit;
    z-index: 9999;
    &-route{
        height:auto;
        overflow: hidden; 
    }
    &-scroll{
        max-height: inherit;
        padding-bottom: 80px;
        @media screen and (max-width: 900px) {
            padding-bottom: 140px;
        }
    }

    &-submit{
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
    }
    &-close{
        display: flex;
        justify-content: flex-end;
        button{
            width: 80px;
            height: 80px;
            @include flex-all-center;
        }
    }
    .basket-title {
        text-decoration: none;
        height: 80px;
        padding: 0;
        align-items: flex-start;
        @media screen and (max-width: 900px) {
            padding: 0;
        }
    }
    .catalog-ratio {
        border: 1px solid $color_white;
    }
    &-body {
      
     
        padding: 0 2px;
        margin: 28px 28px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    &-header {
        &-login {
            padding: 0px 0 45px 37px;
            color: $color_grey2;
            font-size: 20px;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: 0%;

            button {
                text-decoration: underline;
                color: $color_white;
            }
            @media screen and (max-width: 900px) {
                font-size: 16px;
                padding: 0px 0 15px 37px;
            }
        }
        &-fop {
            color: $color_white;
            font-size: 20px;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: 0%;
            display: grid;
            text-align: center;
            grid-template-columns: 1fr  auto auto;
            gap: 10px;
            padding: 0px 30px;
            align-items: center;
            &-item{
                display: flex;
                gap: 10px;
                align-items: center;
            }
            @media screen and (max-width: 900px) {
                font-size: 16px;
                grid-template-columns: auto 1fr auto;
                padding: 0 50px;
            }
        }
    }
    &-input {
        border: 1px solid $color_grey1;
        background: rgba(255, 255, 255, 0.8);
     
        font-family: "DIN Pro";
        color: $color_black;
        padding: 12px;
        width: 100%;
        &::placeholder {
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            letter-spacing: 0%;
            color: $color_black;
        }
        &-body{
            display: flex;
            width: 100%;
            position: relative;
        }
        &-error{
            position: absolute;
            top: 47px;
            left: 13px;
            color: red;
            font-size: 13px;
            font-size: 700;
            letter-spacing: 1px; 

        }
    }
    &-paymant {
        display: flex;

        padding: 0 15px;
        font-size: 20px;
        font-weight: 400;
        line-height: 23px;
        letter-spacing: 0%;
        color: $color_white;
        padding-bottom: 30px;
        flex-grow: 2;
        @media screen and (max-width: 900px) {
            font-size: 16px;
        }
        &-ratio {
            display: flex;
            padding-left: 20px;
            height: 20px;
            gap: 8px;
        }
        &-text {
            padding-left: 40px;
        }
     
    }
    &-policy {
        display: flex;
        gap: 12px;
        margin: 0px 0px 44px 46px;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0%;
        color: $color_white;
        span {
            text-decoration: underline;
        }
    }
}

.login{
    .auth-header-login{
        padding-bottom: 10px !important; 
    }
}
.login-forget-pass{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 54px;
    &-ratio{
        display: flex;
        align-items: center;
        gap:10px;
    }
}

.forget-pass{
    .auth-header-login{
        p{
            text-align: center;
        }
    }
    .auth-input-body{
        padding-bottom: 100px;
    }
}

.dev-fg-pass{
    .auth-scroll{
        padding-bottom: 38px !important;
    }
    &-img{
        width: 205px;
        height: 237px;
        margin:  39px auto;

        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    &-buttom{
        height: 60px;
        width: calc(100% - 130px);
        display: grid;
        grid-template-columns: 1fr 60px;
        grid-template-rows: 60px;
        margin: 0 65px;
        background: linear-gradient(135deg, #4aff0b 0%, #00e5ff 100%);
        @media screen and (max-width: 900px) {
            width: calc(100% - 50px);
            margin: 0 25px;
        }
        h5{
            font-size: 19px;
            font-weight: 800;
            @include flex-all-center;
            height: 60px;
            border-right: 1px solid $color_grey5;
        }
        h6{
            height: 60px;
            @include flex-all-center;
        }
    }
}