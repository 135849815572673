@import "variables.scss";
@import "mixin.scss";
@import "default-style.scss";

@font-face {
    font-family: "DINPro-CondensedRegular";
    src: url("../../public/fonts/dinpro_condensedregular.otf") format("otf");
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: "DINPro-CondensedLight";
    src: url("../../public/fonts/DINPro-CondensedLight.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "DIN Pro";
    src: url("../../public/fonts/DINPro.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Gilroy";
    src: url("../../public/fonts/Gilroy-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Inter";
    src: url("../../public/fonts/Inter-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

body {
    margin: 0;
    font-family: "DINPro-CondensedLight", "DIN Pro", "Gilroy", "Inter", "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    width: 100%;
    max-width: 100vw;
    min-height: -webkit-fill-available;
    position: relative;
    // color: $color_grey;
    background: $color_grey;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.App{
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.Main{
    flex-grow: 1;
    display: grid;
    grid-template-columns: 80px 1fr 81px;
    overflow-x: hidden;
    width: 100%;
    max-width: 100vw;
}

// .Router{
//     overflow-x: hidden;
// }
.no-scroll {
    overflow: hidden;
}

@media screen and (max-width: 700px) {
    .Router{
        position: absolute;
        top: 0px;
        left: 80px;
        max-width: calc(100% - 80px);
        min-height: calc(100vh - 160px);
        width: 100%;
        height: 100%;
        z-index: 101;
        overflow-x: hidden;
    }
    .Main{
        position: relative;
         grid-template-columns:  80px 1fr;
       
    }
  }
  

  .custom--scroll {
    overflow: scroll; 
  
    &::-webkit-scrollbar {
      width: 4px;  height: 4px;
    }
  
    &::-webkit-scrollbar-track {
      background-color: $color_white1; 
      border-radius: 100px; 
    }
  
    &::-webkit-scrollbar-thumb {
        background-image: $backgroundgradient1;
      border-radius: 100px; 
    }
  }
//   .custom--scroll--dark{
//     &::-webkit-scrollbar-track {
//         background-color: $color_grey; // Фон трека скроллбара
 
//       }
//       &::-webkit-scrollbar-thumb {
//         background-color: $color_white1; // Цвет ползунка скроллбара
     
//       }
//   }